import partyimg from "../image/partyimg.jpg";
import Program from "./Program";
import Lineup from "./Lineup";
import Contacts from "./Contacts";
import Windowspropusc from "./Windowspropusc";
import { useState } from "react";

function PartyPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div
        className="relative h-screen w-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${partyimg})`,
        }}
      >
        <header className="absolute top-0 w-full flex flex-col md:flex-row justify-between p-4 md:p-6 lg:px-32 z-10">
          <div className="flex space-x-4 overflow-x-auto">
            <a href="#place" className="text-white hover:text-gray-300 px-4 text-base md:text-xl">Место</a>
            <a href="#partners" className="text-white hover:text-gray-300 px-4 text-base md:text-xl">Лайнап</a>
            <a href="#program" className="text-white hover:text-gray-300 px-4 text-base md:text-xl">Программа</a>
            <a href="#contacts" className="text-white hover:text-gray-300 px-4 text-base md:text-xl">Контакты</a>
          </div>
          <div className="text-white flex items-center mt-2 md:mt-0">
            <span className="mr-4">+7 (777) 777‒77‒77</span>
            <button className="px-4 py-2 bg-white text-black rounded-full hover:bg-gray-200" onClick={() => setIsModalOpen(true)}>
              Получить пропуск
            </button>
            <Windowspropusc isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          </div>
        </header>

        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
          <h1 className="text-3xl md:text-4xl font-bold">Масштабная вечеринка 2024 года</h1>
          <p className="mt-2 text-lg md:text-xl">13 ноября | Самара, Чекистов, 132а</p>
        </div>
      </div>

      <div id="place" className="w-full bg-gray-200 py-10 px-4 flex text-center justify-center">
        <p className="text-base md:text-xl text-gray-600">
          Самое масштабное мероприятие 2024 года в России пройдет 13 ноября в бане по <br />
          адресу Чекистов 132а. Праздник организован в честь <br /> дня рождения известного стримера.
        </p>
      </div>

      <div id="partners" className="px-4">
        <Lineup />
      </div>
      <div id="program" className="px-4">
        <Program />
      </div>
      <div id="contacts" className="px-4">
        <Contacts />
      </div>

      <div className="w-full bg-gray-200 py-5 flex text-center justify-center">
        <p className="text-base text-gray-600">
          Информация на сайте <br />
          не является публичной афертой.
        </p>
      </div>
    </>
  );
}

export default PartyPage;