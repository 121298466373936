import { useState } from "react";
import axios from "axios"; // Импорт Axios

function Windowspropusc({ isModalOpen, setIsModalOpen }) {
  const [isOver18, setIsOver18] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",        // Инициализация полей
    First_name: "",  // Инициализация полей
    Email: "",       // Инициализация полей
  });
  const [isSuccess, setIsSuccess] = useState(false); // Состояние для отображения успеха

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value || "", // Убедитесь, что значение всегда строка
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsOver18(e.target.checked);
  };

  const handleSubmit = async () => {
    if (isOver18 && formData.Name && formData.First_name && formData.Email) {
      try {
        await axios.post("http://localhost:8080/api/tickets", formData); // Отправка данных на бэкенд
        setIsSuccess(true); // Успех
        setTimeout(() => {
          setIsSuccess(false); // Скрытие иконки после 3 секунд
          setIsModalOpen(false); // Закрытие модального окна
        }, 3000);
      } catch (error) {
        console.error("Ошибка при отправке данных:", error);
      }
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Оформление пропуска
            </h2>
            {/* Поля ввода */}
            <div className="mb-4">
              <label className="block text-black mb-2">Имя</label>
              <input
                type="text"
                name="Name" // Исправлено на правильное имя
                value={formData.Name} // Исправлено на правильное имя
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-yellow-500 text-black"
                placeholder="Введите ваше имя"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Фамилия</label>
              <input
                type="text"
                name="First_name" // Исправлено на правильное имя
                value={formData.First_name} // Исправлено на правильное имя
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-yellow-500 text-black"
                placeholder="Введите вашу фамилию"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Электронная почта
              </label>
              <input
                type="email"
                name="Email" // Исправлено на правильное имя
                value={formData.Email} // Исправлено на правильное имя
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-yellow-500 text-black"
                placeholder="Введите ваш email"
              />
            </div>
            {/* Сообщение о правильности данных */}
            <p className="text-xs text-gray-600 mb-4">
              Пожалуйста, введите корректные данные. При входе на вечеринку может потребоваться паспорт и билет будет выслан на адрес электронной почты которую вы впишите.
            </p>
            {/* Чекбокс подтверждения возраста */}
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={isOver18}
                onChange={handleCheckboxChange}
                className="mr-2 h-4 w-4"
              />
              <label className="text-gray-700">
                Я подтверждаю, что мне больше 18 лет
              </label>
            </div>
            {/* Кнопка отправки */}
            <button
              onClick={handleSubmit}
              className={`w-full py-2 px-4 rounded-full ${
                isOver18
                  ? "bg-yellow-500 hover:bg-yellow-600"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              disabled={!isOver18}
            >
              Отправить
            </button>
            {/* Успешная отправка */}
            {isSuccess && (
              <div className="mt-4 flex items-center justify-center text-green-600">
                <span>Пропуск успешно отправлен на электронную почту!</span>
              </div>
            )}
            {/* Кнопка закрытия модального окна */}
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Windowspropusc;