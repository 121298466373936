
import ttd from "../image/ttd.jpg";
import morgen from "../image/Morgen.jpg";
import P_didy from "../image/P_didy.jpg";
import Toxis from "../image/Toxis.jpg";
import Eva from "../image/Eva.jpg";

function Lineup() {
  return (
    <div className="w-full bg-gray-50 py-16 text-center">
      {/* Заголовок секции */}
      <h2 className="text-3xl font-bold text-gray-800 mb-12">Лайнап</h2>

      {/* Контейнер для артистов */}
      <div className="flex justify-center space-x-32 ">
        {/* Три дня дождя*/}
        <div className="flex flex-col items-center ">
          <div className="w-44 h-44 rounded-full overflow-hidden">
            <img
              src={ttd}
              alt="Три дня дождя"
              className="w-full h-full object-cover"
            />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">
            Три дня дождя
          </h3>
          <p className="text-gray-600">Россия,Кызыл</p>
        </div>

        {/* Моргенштерн */}
        <div className="flex flex-col items-center ">
          <div className="w-44 h-44 rounded-full overflow-hidden">
            <img
              src={morgen} // Заменить на реальный путь к изображению
              alt="Моргенштерн"
              className="w-full h-full object-cover"
            />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">
            Моргенштерн
          </h3>
          <p className="text-gray-600">Россия,Уфа</p>
        </div>

        {/* P_didy */}
        <div className="flex flex-col items-center ">
          <div className="w-44 h-44 rounded-full overflow-hidden">
            <img
              src={P_didy} // Заменить на реальный путь к изображению
              alt="P.Diddy"
              className="w-full h-full object-cover"
            />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">P.Diddy</h3>
          <p className="text-gray-600">США,Нью-Йорк</p>
        </div>

        {/* Тимати */}
        <div className="flex flex-col items-center ">
          <div className="w-44 h-44 rounded-full overflow-hidden">
            <img
              src={Toxis} // Заменить на реальный путь к изображению
              alt="Toxis"
              className="w-full h-full object-cover"
            />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">Toxis</h3>
          <p className="text-gray-600">Россия,Москва</p>
        </div>
         {/* Ева элфи */}
         <div className="flex flex-col items-center ">
          <div className="w-44 h-44 rounded-full overflow-hidden">
            <img
              src={Eva} // Заменить на реальный путь к изображению
              alt="Ева элфи"
              className="w-full h-full object-cover"
            />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">Ева Эльфи</h3>
          <p className="text-gray-600">Россия,Омск</p>
        </div>
      </div>
    </div>
  );
}

export default Lineup;
