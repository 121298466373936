
import ttd from "../image/ttd.jpg";
import morgen from "../image/Morgen.jpg";
import P_didy from "../image/P_didy.jpg";
import Toxis from "../image/toxisssssssssss.jpg";
import Eva from "../image/Evaaaaa.jpg";
function Program() {
  return (
    <div className="w-full bg-gray-200 py-16">
      <h2 className="text-center text-2xl font-semibold text-gray-800 mb-4">
        Программа
      </h2>
      <p className="text-center text-lg text-gray-600 mb-8">13 ноября 2024</p>

      <div className="space-y-8">
      <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            17:00 - 17:05
          </span>
          <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex items-center justify-center">
            {/* Пустой круг */}
          </div>
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Начало праздника
          </h3>
        </div>
        {/* Первое выступление */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            17:05 - 17:30
          </span>
          <img
            src={morgen} // Замените на реальный путь к изображению
            alt="morgen"
            className="w-16 h-16 rounded-full"
          />
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Выступление Моргенштерн
          </h3>
        </div>

        {/* Второе выступление */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            17:30 - 18:00
          </span>
          <img
            src={ttd} // Замените на реальный путь к изображению
            alt="Три дня дождя"
            className="w-16 h-16 rounded-full"
          />
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Выступление Три дня дождя
          </h3>
        </div>

        {/* Пустое выступление */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            18:00 - 18:30
          </span>
          <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex items-center justify-center">
            {/* Пустой круг */}
          </div>
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
                Драка вениками
          </h3>
        </div>
        {/* Пустое выступление */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            18:30 - 19:00
          </span>
          <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex items-center justify-center">
            {/* Пустой круг */}
          </div>
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
                Бухаем
          </h3>
        </div>
         {/* Четвертое выступление */}
         <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            19:00 - 19:30
          </span>
          <img
            src={Eva} // Замените на реальный путь к изображению
            alt="eva elfi"
            className="w-16 h-16 rounded-full"
          />
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Стриптиз(Выступление Евы Эльфи)
          </h3>
        </div>
        {/* Четвертое выступление */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            19:30 - 20:00
          </span>
          <img
            src={P_didy} // Замените на реальный путь к изображению
            alt="P.Diddy"
            className="w-16 h-16 rounded-full"
          />
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Выступление P.Diddy
          </h3>
        </div>

        {/* Пятое выступление с пустым кругом */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            20:00 - 20:30
          </span>
          <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex items-center justify-center">
            {/* Пустой круг */}
          </div>
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Сжигаем чучело ...(секрет)
          </h3>
        </div>

        {/* Шестое выступление */}
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            20:30 - 21:00
          </span>
          <img
            src={Toxis} // Замените на реальный путь к изображению
            alt="Toxis"
            className="w-16 h-16 rounded-full"
          />
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Выступление Toxis
          </h3>
        </div>
        <div className="flex items-center justify-between max-w-xl mx-auto">
          <span className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            21:00 - 21:05
          </span>
          <div className="w-4 h-4 rounded-full border-2 border-gray-300 flex items-center justify-center">
            {/* Пустой круг */}
          </div>
          <h3 className="text-lg font-semibold text-gray-800 w-1/3 text-center">
            Прощаемся...
          </h3>
        </div>
      </div>
    </div>  
  );
}

export default Program;
